import { PAYMENT_METHOD, SALES_ORDER_STATUS } from '../../Domain/Payment/constants';

export enum PURCHASE_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export interface ShipmentDelivery {
  shipmentProvider: string;
  trackingNumber?: string;
  trackingURL?: string;
}

export interface Shipment {
  shipmentMethod: string;
  status: string;
  delivery: ShipmentDelivery;
}

export interface Product {
  cardImageUrl: string;
  title: string;
}

type paymentActionFuncType = () => void;

export interface Payment {
  method: PAYMENT_METHOD;
  action?: string | paymentActionFuncType;
}

export interface PurchaseHistoryProps {
  orderNo: string;
  status: SALES_ORDER_STATUS;
  price: number;
  payment: Payment;
  createdAt: Date;
  expirationDate?: Date | null;
  paidAt?: Date | null;
  product: Product;
  shipment?: Shipment;
}

export interface PaymentInfoProps {
  status: SALES_ORDER_STATUS;
  text: string;
  paymentAction: Payment['action'];
}
