import gql from 'graphql-tag';

const paymentUpsertPreSalesOrder = gql`
  mutation sfFoPaymentUpsertPreSalesOrder($input: SFFoPaymentUpsertPreSalesOrderInput!) {
    sfFoPaymentUpsertPreSalesOrder(input: $input) {
      id
      priceInclVAT
      discountInclVAT
      totalAmountExclVAT
      totalAmount
      paymentAmount
      discountCode
      withholdingTax
      preSalesOrderItems {
        title
        totalPrice
        quantity
        SKUType
        SKUCategory
        stock
        SKUStatus
        permalink
        parentProduct {
          permalink
          coverImage {
            key
            bucket
          }
          coverImageUrl
        }
        categories {
          categories {
            permalink
          }
          parentPermalink
        }
        eventTime {
          startDate
          endDate
          startTime
          endTime
        }
        cardImage {
          key
          bucket
        }
        cardImageUrl
        meta {
          ... on SFFoPreSaleOrderItemMetaSubscription {
            conditions {
              ... on SFConditionDaysValidity {
                type
                days
              }
            }
          }
        }
        SKUItems {
          SKUCategory
          title
          meta {
            ... on SFFoPreSaleOrderItemMetaSubscription {
              conditions {
                ... on SFConditionDaysValidity {
                  type
                  days
                }
              }
            }
          }
        }
      }
    }
  }
`;

const placeOrderWithOfflineTransaction = gql`
  mutation sfFoPlaceOrderWithOfflineTransaction($input: SFPlaceOrderWithOfflineTransactionInput!) {
    sfFoPlaceOrderWithOfflineTransaction(input: $input) {
      isSuccess
      task
      responseUrl
      error
      salesOrder {
        id
        orderNo
        type
        discountCode
        VAT
        paymentAmount
        paymentMethod
      }
    }
  }
`;

export { paymentUpsertPreSalesOrder, placeOrderWithOfflineTransaction };
