import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { usePayment } from '../../Contexts/PaymentProvider';
import PurchaseOrderListView from '../../Components/PurchaseOrderList';
import { CUSTOMER_TYPE } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import { useConfig } from '../../Contexts/ConfigContext';
import { WorkshopSeatSelectionOptionProps } from '../../Components/PurchaseOrderList/types';

const DEFAULT_MAX_WORKSHOP_SEAT_SELECTION: number = 5;

const mapWorkshopSeatSelectionOptions = (
  inStockWorkshopSeats?: number,
  maxWorkshopSeatSelection?: number
): WorkshopSeatSelectionOptionProps[] => {
  const options: WorkshopSeatSelectionOptionProps[] = [];

  let availableWorkshopSeats = maxWorkshopSeatSelection ?? DEFAULT_MAX_WORKSHOP_SEAT_SELECTION;

  if (inStockWorkshopSeats && inStockWorkshopSeats > 0) {
    if (inStockWorkshopSeats < availableWorkshopSeats) {
      availableWorkshopSeats = inStockWorkshopSeats;
    }
  } else {
    availableWorkshopSeats = 0;
  }

  for (let index = 1; index <= availableWorkshopSeats; index++) {
    options.push({
      label: index.toString(),
      value: index,
    });
  }
  return options;
};

const PurchaseOrderList = (): JSX.Element => {
  const {
    courseTitle,
    productPrice,
    productImageUrl,
    customerType,
    productType,
    updateProductQuantity,
    workshopEventTimeText,
    isVirtualWorkshop,
    stock,
  } = usePayment();
  const {
    maxWorkshopSeatSelection,
    enableProductTypeBadge,
    disableCorporateCourseAvailableTimeText,
  } = useConfig();
  const productQuantity = useWatch({ name: 'productQuantity', defaultValue: 1 });
  const [productPricePerUnit, setProductPricePerUnit] = useState<number>();

  useEffect(() => {
    updateProductQuantity(productQuantity);
  }, [productQuantity, updateProductQuantity]);

  useEffect(() => {
    if (typeof productPricePerUnit === 'undefined' && typeof productPrice === 'number') {
      setProductPricePerUnit(new Decimal(productPrice).dividedBy(productQuantity).toNumber());
    }
  }, [productPrice]);

  return (
    <PurchaseOrderListView
      productType={productType}
      productTitle={courseTitle ?? ''}
      totalPrice={productPricePerUnit}
      productImageUrl={productImageUrl}
      isCorporateCustomer={customerType === CUSTOMER_TYPE.CORPORATE}
      workshopEventTimeText={workshopEventTimeText}
      workshopSeatSelectionOptions={mapWorkshopSeatSelectionOptions(
        stock,
        maxWorkshopSeatSelection
      )}
      shouldShowProductTypeBadge={enableProductTypeBadge}
      isVirtualWorkshop={isVirtualWorkshop}
      disableCorporateCourseAvailableTimeText={disableCorporateCourseAvailableTimeText}
    />
  );
};

export default PurchaseOrderList;
