import styled, { css } from 'styled-components';
import numeral from 'numeral';
import {
  layout,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  BorderProps,
  FlexProps,
} from 'styled-system';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Box,
  Text,
  SVGIcon,
  ICON_LIST,
  Flex as BaseFlex,
} from '@skooldio/paas-shared-react-components';

import SectionTitle from '../SectionTitle';

import { PurchaseOrderListProps, WorkshopSeatSelectionOptionProps } from './types';

import { SKU_CATEGORY } from '../../Contexts/PaymentProvider/PaymentProvider.d';
import FormSelectionInput from '../FormInput/FormSelectionInput';

interface PricingTextProps
  extends TypographyProps,
    SpaceProps,
    BorderProps,
    LayoutProps,
    FlexProps {
  totalPrice?: number;
}

const Flex = styled(BaseFlex)`
  ${layout}
`;

const ProductImage = styled.img`
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.md}) {
      height: 100px;
    }
  `}
`;

const Badge = styled.div`
  color: ${({ theme }) => theme.colors.primary[700]};
  background-color: ${({ theme }) => theme.colors.primary[100]};
  border-radius: 32px;
  padding: ${({ theme }) => `2px ${theme.space[2]}px`};
  line-height: 17px;
  width: fit-content;
`;

const VirtualWorkshopBadge = styled.div`
  display: flex;
  align-items: center;
  color: white;
  background-color: #1a9bfa;
  border-radius: 32px;
  padding: ${({ theme }) => `2px ${theme.space[2]}px`};
  line-height: 17px;
  width: fit-content;
`;

const getProductTypeDisplayText = (productType?: SKU_CATEGORY) => {
  switch (productType) {
    case SKU_CATEGORY.ONLINE_COURSE:
      return 'Online Course';
    case SKU_CATEGORY.WORKSHOP_BATCH:
      return 'Workshop';
    case SKU_CATEGORY.COLLECTION:
      return 'Package';
    default:
      return null;
  }
};

const ProductTypeBadge = ({
  productType,
  isVirtualWorkshop,
}: {
  productType?: SKU_CATEGORY;
  isVirtualWorkshop?: boolean;
}) => {
  if (isVirtualWorkshop) {
    return (
      <VirtualWorkshopBadge>
        <SVGIcon name={ICON_LIST.ZOOM} />
        <Text textStyle="caption" fontWeight="500" ml={1}>
          VIRTUAL
        </Text>
      </VirtualWorkshopBadge>
    );
  }

  return (
    <Badge>
      <Text textStyle="caption" fontWeight="500">
        {getProductTypeDisplayText(productType)}
      </Text>
    </Badge>
  );
};

const PricingText = ({ totalPrice, display, ...props }: PricingTextProps) => {
  const { t } = useTranslation();

  if (typeof totalPrice === 'undefined') {
    return null;
  }

  return (
    <Text
      textStyle="body1"
      color="text.darkText"
      fontWeight="300"
      textAlign="right"
      display={display}
      {...props}
    >
      <Text mr={2} as="span">
        {totalPrice === 0 ? (
          t('free')
        ) : (
          <span>{t('price_with_currency', { price: numeral(totalPrice).format('0,0') })}</span>
        )}
      </Text>
    </Text>
  );
};

const WorkshopSeatSelection = ({ options }: { options: WorkshopSeatSelectionOptionProps[] }) => (
  <Flex alignItems="center">
    <Text mr={2} fontWeight="300">
      x
    </Text>
    <Box width="fit-content">
      <FormSelectionInput name="productQuantity" defaultValue={1} options={options} />
    </Box>
  </Flex>
);

const PurchaseOrderListWrapper = styled(Grid)`
  grid-template-rows: 160px auto;
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.md}) {
      grid-template-rows: auto;
      grid-template-columns: 180px 1fr;
      grid-column-gap: ${theme.space[5]}px;
    }
  `}
`;

const PurchaseOrderList = (props: PurchaseOrderListProps) => {
  const {
    productTitle,
    totalPrice,
    productImageUrl,
    isCorporateCustomer,
    productType,
    workshopEventTimeText,
    workshopSeatSelectionOptions,
    shouldShowProductTypeBadge,
    isVirtualWorkshop,
    disableCorporateCourseAvailableTimeText,
  } = props;
  const { t } = useTranslation();

  return (
    <Box maxWidth={800} width="100%" m="0">
      <SectionTitle title={t('purchase_product_list')} />
      <PurchaseOrderListWrapper>
        {productImageUrl ? (
          <ProductImage src={productImageUrl} />
        ) : (
          <Box
            bg="grayscale.100"
            borderRadius="12px"
            width="100%"
            height={{ _: '100%', md: '100px' }}
          />
        )}
        <Box pt={{ _: 3, md: 0 }}>
          {shouldShowProductTypeBadge && (
            <ProductTypeBadge productType={productType} isVirtualWorkshop={isVirtualWorkshop} />
          )}

          <Flex justifyContent="space-between" pt={1}>
            <Flex alignItems="center">
              <Text textStyle="body1" color="text.darkText" fontWeight="300">
                {productTitle}
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              display={{ _: 'none', md: 'flex' }}
              flex="0 0 auto"
              height="fit-content"
            >
              <PricingText totalPrice={totalPrice} ml={5} />
              {productType === SKU_CATEGORY.WORKSHOP_BATCH && (
                <WorkshopSeatSelection options={workshopSeatSelectionOptions} />
              )}
            </Flex>
          </Flex>

          {workshopEventTimeText && (
            <Text textStyle="body2" color="text.darkTextAlt" pt={{ _: 1, md: 0 }}>
              {workshopEventTimeText}
            </Text>
          )}
          {!disableCorporateCourseAvailableTimeText &&
            isCorporateCustomer &&
            (productType === SKU_CATEGORY.ONLINE_COURSE ||
              productType === SKU_CATEGORY.COLLECTION) && (
              <>
                <Text
                  textStyle="body2"
                  color="primary.500"
                  fontWeight="600"
                  pt={1}
                  mr={1}
                  display="inline-block"
                >
                  {t('purchase_course_1_year_access')}
                </Text>
                <Text textStyle="body2" color="text.darkTextAlt" display="inline-block">
                  {t('purchase_pay_as_juristic_person')}
                </Text>
              </>
            )}
        </Box>
      </PurchaseOrderListWrapper>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        display={{ _: 'flex', md: 'none' }}
        pt={4}
      >
        <PricingText totalPrice={totalPrice} />
        {productType === SKU_CATEGORY.WORKSHOP_BATCH && (
          <WorkshopSeatSelection options={workshopSeatSelectionOptions} />
        )}
      </Flex>
    </Box>
  );
};

export type { PurchaseOrderListProps };
export default PurchaseOrderList;
