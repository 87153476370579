import _ from 'lodash';

const defaultConfig = {
  // Skooldio Staging endpoint
  // USER_API: 'https://api.soylatte.skooldio.com/store/users',
  // GOOGLE_AUTHENTICATION_PATH: 'https://api.soylatte.skooldio.com/store/login/google',
  // BROWSER_STORE_GRAPHQL: 'https://api.soylatte.skooldio.com/store/graphql',
  dataSources: {
    address: '/datasources/addressTH.js',
  },
  HTMLMeta: {
    // default
    title: 'Skooldio Payment',
    description: 'Skooldio Payment',
    pageTitleSuffix: '',

    // Open Graph
    OGURL: 'https://www.skooldio.com/',
    OGTitle: 'Skooldio Payment',
    OGDescription: 'Skooldio Payment',
    OGImage: '',

    // Twitter
    twitterCard: 'summary_large_image',

    // favicon
    faviconPath: '/static/favicon-tmk/',
  },

  appShell: {
    mainNavMenuItems: [],
    footer: {
      title: '',
      subtitle: '',
      showBadge: false,
    },
    header: {
      enableSecondaryButton: false,
      secondaryButtonURL: '',
    },
  },
  // Marketplace Staging endpoint
  userAPI: 'https://api.marketplace.paas.staging.skooldio.app/user/api/users',
  profileURL: 'https://store.marketplace.paas.staging.skooldio.app/account/profile',
  classroomURL: 'https://classroom.marketplace.paas.staging.skooldio.app/classroom/:courseId',
  myCourseURL: 'https://classroom.marketplace.paas.staging.skooldio.app',

  // Storefront
  storeURL: 'https://store.marketplace.paas.staging.skooldio.app',
  storefrontType: 'LMS',

  googleAuthenticationPath: 'https://api.soylatte.skooldio.com/store/login/google',
  loginUrl: 'https://store.marketplace.paas.staging.skooldio.app/login',
  browserStoreGraphQL: 'https://api.marketplace.paas.staging.skooldio.app/store/graphql',

  // Note: The {{orderId}} will be resolved in backend
  // when is assigned to 'resultUrl' field of PlaceOrderWithOfflineTransactionMutation payload.
  thankYouPageURL:
    'https://payment.marketplace.paas.staging.skooldio.app/orders/{{orderId}}/thank-you',
  // To display thank you page in each UI variant
  // Options: 'default', 'downloadApp'
  thankYouPageVariant: 'default',

  // configs that available on 'downloadApp' variant
  // ------------------------------------------------
  //  Supported application stores
  //  The temporary urls below are default until application store urls are ready.
  macStoreUrl: 'https://store.marketplace.paas.staging.skooldio.app/',
  windowsStoreUrl: 'https://store.marketplace.paas.staging.skooldio.app/',
  appStoreUrl: 'https://store.marketplace.paas.staging.skooldio.app/',
  playStoreUrl: 'https://store.marketplace.paas.staging.skooldio.app/',
  downloadApp: {
    brandAppIconURL: undefined,
    title: undefined,
    description: undefined,
  },

  // ------------------------------------------------

  disableUserAccountMenuInPurchasePage: false,
  userAccountMenu: {
    enableEnterClassroom: false,
    // enterClassroomDestination value supports the following string: 'MY_COURSES' and 'GET_APP'(default)
    enterClassroomDestination: 'GET_APP',
    disableEnterProfileAccount: false,
    disableEnterPurchaseHistory: false,
    enableEnterMyCertificates: false,
  },

  analytics: {
    googleAnalyticsID: '',
    customerGoogleAnalyticsID: '',
    platformGoogleTagManager: {
      id: '',
      gtmAuthToken: undefined,
      gtmPreviewId: undefined,
    },
    customerGoogleTagManager: {
      id: '',
      gtmAuthToken: undefined,
      gtmPreviewId: undefined,
    },
  },

  purchaseHistoryCardsPerPage: 5,
  brandLogoSrcSet: {
    '1x': '/static/images/skooldio_logo.png',
    '2x': '/static/images/skooldio_logo@2x.png',
  },
  brandLogoHref: '/',

  external: {
    termsUrl: 'https://www.skooldio.com/terms',
    privacyUrl: 'https://www.skooldio.com/privacy',
    dataProtectionPolicyUrl: undefined,
    refundPolicyUrl: undefined,
  },

  enablePurchaseAsCorporate: false,
  corporateNamePlaceholder: 'เช่น สคูลดิโอ จำกัด',
  enablePhoneNumberField: false,
  maxWorkshopSeatSelection: 5,
  disableValidateUserInformation: false,
  enableProductTypeBadge: false,
  enableAnonymousUser: true,
  disableCorporateCourseAvailableTimeText: false,

  discountCodeParamKey: 'd',
  discountCodeRemark: {
    // selectedCodeList: ['LUCKY_990', 'LUCKY_1490', 'LUCKY_1990', 'LUCKY_2990', 'LUCKY_3490'],
    // remarkText:
    //   'รับทราบเงื่อนไขโปรโมชั่น คอร์สนี้มีอายุ 7 วันหลังชำระเงินสำเร็จและจะไม่สามารถขอคืนเงินหรือเปลี่ยนคอร์สได้',
  },

  userConsent: {
    enablePDPAConsent: false,
    PDPAConsentVersion: '20220601',
    redirectUrl: undefined,
  },
  payment: {
    // paymentMethodOptions array supports the following payment methods: 'CREDIT_CARD', 'QR_CODE' and 'ATM'
    // If methodOptions value is undefined or empty array, all payment methods are enabled
    methodOptions: ['CREDIT_CARD', 'QR_CODE'],

    individual: {
      //'ONLINE', 'DELIVER_BY_SUPPLIER'
      taxInvoiceDeliveryMethod: 'ONLINE',

      // 'สำหรับบุคคลทั่วไป ตรวจสอบอีเมลของคุณหลังชำระเงินสำเร็จคุณจะได้รับใบกำกับภาษี/ใบเสร็จรับเงินอิเล็กทรอนิกส์ภายใน 1 สัปดาห์',
      taxInvoiceInfo:
        'สำหรับบุคคลทั่วไป ตรวจสอบอีเมลของคุณหลังชำระเงินสำเร็จคุณจะได้รับใบกำกับภาษี/ใบเสร็จรับเงินอิเล็กทรอนิกส์ภายใน 1 สัปดาห์',

      taxInvoiceInfoOptions: [],

      enableRequestTaxInvoice: true,
    },

    corporate: {
      //'ONLINE', 'DELIVER_BY_SUPPLIER'
      taxInvoiceDeliveryMethod: 'ONLINE',

      // 'สำหรับนิติบุคคล กรุณาตรวจสอบอีเมลของคุณหลังชำระเงินสำเร็จเพื่อดูขั้นตอนการรับใบกำกับภาษี/ใบเสร็จรับเงิน',
      taxInvoiceInfo:
        'สำหรับนิติบุคคล กรุณาตรวจสอบอีเมลของคุณหลังชำระเงินสำเร็จเพื่อดูขั้นตอนการรับใบกำกับภาษี/ใบเสร็จรับเงิน {{taxInvoiceInstructionsURL}}',

      taxInvoiceInfoOptions: [
        {
          pattern: '{{taxInvoiceInstructionsURL}}',
          label: 'คลิกเพื่อดูขั้นตอนเพิ่มเติม',
          url: 'https://assets.skooldio.com/public/instructions_for_tax_invoice_request.pdf',
        },
      ],

      enableRequestTaxInvoice: true,

      // branchInfoOptions: {
      //   branchInputLabel: null,
      //   branchInputPlaceholder: null,
      //   branchNameValidation: [
      //     {
      //       regex: '^S.*[^.s]$',
      //       errorMessage: 'ข้อมูลสาขามีช่องว่างนำหน้าหรือต่อท้าย',
      //     },
      //   ],
      // },
    },
  },
};

const mergeCustomizer = (objectValue, sourceValue) => {
  if (Array.isArray(objectValue) && objectValue.length > 0) return sourceValue;
};

const mergeConfigOverrides = (config, ...args) => {
  const resultConfig = _.mergeWith({}, defaultConfig, config, ...args, mergeCustomizer);
  return resultConfig;
};

export { defaultConfig, mergeConfigOverrides };

export default defaultConfig;
