import { useCallback, useMemo, useState } from 'react';
import isUrl from 'is-url';
import {
  LoadingModal,
  PurchaseHistoryCard,
  PurchaseHistoryProps,
} from '@skooldio/paas-shared-react-components';

import { useConfig } from '../../Contexts/ConfigContext';
import { usePurchaseHistoryPaging } from '../../Contexts/PurchaseHistoryPagingProvider';
import { SALES_ORDER_STATUS, SHIPMENT_METHOD } from '../../Domain/useMySalesOrderData.d';
import useMySalesOrderData from '../../Domain/useMySalesOrderData';
import { Grid } from '../../Components/Base';
import {
  PAYMENT_METHOD,
  CUSTOMER_TYPE,
  SKU_CATEGORY,
} from '../../Contexts/PaymentProvider/PaymentProvider.d';
import use2C2PRedirectURL from '../../Domain/use2C2PRedirectURL';
import { sendDataToGTM } from '../../Utils/analytics/googleTagManager';

import {
  mapGTMPaymentMethodValue,
  mapGTMProductTypeValue,
  mapGAEventCategoryValue,
} from '../../Utils/analytics/mapping';

type paymentActionFuncType = () => void;

interface Payment {
  method: PAYMENT_METHOD;
  action?: string | paymentActionFuncType;
}

function is2C2PPaymentMethod(paymentMethod: PAYMENT_METHOD) {
  return (
    paymentMethod === PAYMENT_METHOD.ALL ||
    paymentMethod === PAYMENT_METHOD.ATM ||
    paymentMethod === PAYMENT_METHOD.QR_CODE ||
    paymentMethod === PAYMENT_METHOD.CREDIT_CARD
  );
}

const PurchaseHistoryList = () => {
  const { currentPage } = usePurchaseHistoryPaging();
  const { purchaseHistoryCardsPerPage } = useConfig();
  const { mutate2C2PRedirectURL } = use2C2PRedirectURL();
  const [isPaymentActionLoading, setIsPaymentActionLoading] = useState(false);

  const { data: salesOrderData, loading: isSalesOrderDataLoading } = useMySalesOrderData({
    paging: { pageSize: purchaseHistoryCardsPerPage ?? 5, currentPage: currentPage },
  });

  const handle2C2PPaymentAction = useCallback(
    async (salesOrderNo: string) => {
      if (!isPaymentActionLoading) {
        setIsPaymentActionLoading(true);
        const { data } = await mutate2C2PRedirectURL({ salesOrderNo });
        const responseUrl = data?.redirectUrl;
        if (typeof responseUrl === 'string' && isUrl(responseUrl)) {
          window.location.href = responseUrl;
        }
        setIsPaymentActionLoading(false);
      }
    },
    [mutate2C2PRedirectURL, isPaymentActionLoading]
  );

  const mapToPurchaseHistoryData = useCallback(
    (salesOrder: any): PurchaseHistoryProps | null => {
      // assume: have only 1 order item and 1 shipment
      if (!salesOrder.items || salesOrder.items.length < 1) {
        return null;
      }
      const orderItem = salesOrder.items[0];

      const shipment =
        salesOrder.shipments &&
        salesOrder.shipments.find(
          (shipment) => shipment && shipment.shipmentMethod === SHIPMENT_METHOD.DELIVER_BY_SUPPLIER
        );

      const payment: Payment = { method: salesOrder.paymentMethod };
      if (
        salesOrder.status === SALES_ORDER_STATUS.PENDING &&
        is2C2PPaymentMethod(salesOrder.paymentMethod)
      ) {
        payment.action = () => {
          sendDataToGTM({
            event: 'purchase-history',
            skuCode: orderItem?.SKUCode,
            customerType: salesOrder?.customerType,
            discountCode: salesOrder?.discountCode,
            productTitle: orderItem?.title,
            conversionValue:
              salesOrder?.customerType === CUSTOMER_TYPE.CORPORATE
                ? salesOrder?.paymentAmount
                : salesOrder?.totalAmountExclVAT,
            productType: mapGTMProductTypeValue(orderItem?.product?.SKUCategory),
            paymentMethod: mapGTMPaymentMethodValue(salesOrder?.paymentMethod),
            salesOrderNumber: salesOrder.orderNo,
            gaEventCategory: mapGAEventCategoryValue({
              productType: orderItem?.product?.SKUCategory,
              productTitle: orderItem?.title,
              skuCode: orderItem?.SKUCode,
            }),
          });

          handle2C2PPaymentAction(salesOrder.orderNo);
        };
      }

      return {
        orderNo: salesOrder.orderNo,
        status: salesOrder.status,
        price: salesOrder.paymentAmount,
        payment,
        createdAt: new Date(salesOrder.createdAt),
        expirationDate: salesOrder.expirationDate ? new Date(salesOrder.expirationDate) : null,
        paidAt: salesOrder.paidAt ? new Date(salesOrder.paidAt) : null,
        product: {
          title: orderItem.title,
          cardImageUrl:
            orderItem?.product?.SKUCategory === SKU_CATEGORY.WORKSHOP_BATCH
              ? orderItem.parentProduct?.coverImageUrl
              : orderItem.product?.cardImageUrl,
        },
        shipment: shipment,
      };
    },
    [handle2C2PPaymentAction]
  );

  const salesOrders = useMemo<any[]>(() => salesOrderData?.salesOrders ?? [], [salesOrderData]);

  const purchaseHistoryData = useMemo<any[]>(
    () =>
      salesOrders
        .map((salesOrder) => mapToPurchaseHistoryData(salesOrder))
        .filter(
          (purchaseHistoryData): purchaseHistoryData is PurchaseHistoryProps =>
            purchaseHistoryData !== null
        ),
    [salesOrders]
  );

  if (isSalesOrderDataLoading) return null;

  return (
    <>
      <Grid gridTemplateColumns="1fr" gridGap="20px 0" mt="18px">
        {purchaseHistoryData.map((purchaseHistory) => (
          <PurchaseHistoryCard key={purchaseHistory.orderNo} {...purchaseHistory} />
        ))}
      </Grid>
      <LoadingModal isOpen={isPaymentActionLoading} />
    </>
  );
};

export default PurchaseHistoryList;
